import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const TrackPageView = () => {
  const location = useLocation();
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView();
  }, [location, trackPageView]);

  return null;
};

export default TrackPageView;
