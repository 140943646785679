import { createInstance } from "@datapunt/matomo-tracker-react";

// Configuration de Matomo
const matomo = createInstance({
  urlBase: "https://app-matomo.viabber.com/", // Remplacez par l'URL de votre serveur Matomo
  siteId: 1, // Remplacez par l'ID de votre site Matomo
  trackerUrl: "https://app-matomo.viabber.com/matomo.php",
  srcUrl: "https://app-matomo.viabber.com/matomo.js",
  disabled: false, // Désactive Matomo si false
  heartBeat: { active: true, seconds: 10 }, // Envoie un "heartbeat" toutes les 10s
  linkTracking: true, // Active le suivi des liens
  configurations: {
    // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: true,
    setRequestMethod: "POST",
  },
});

export default matomo;
